import React from "react";
import { Route, Redirect } from "react-router-dom";
import ls from '../ls';

const PrivateRoute = (props) => {
  const { component: Component, render, ...rest } = props;

  return (
    <Route
      {...rest}
      render={(props) => {
        return ls.get('userToken') != null ? ( 
          render ? (render(props) ) : Component ? (<Component {...props} />) : null
        ) : (
          <Redirect
            to={{
              pathname: "/log_in",
              state: { from: props.location },
            }}
          />
        )
      }}
    />
  );
};

export default PrivateRoute;
