import React, { useContext, useState } from 'react';
import LogInForm from './components/LogInForm';
import axios from 'axios';
import ls from '../../utils/ls';
import { useHistory } from 'react-router-dom';
import { SystemContext } from '../../contexts';
import logo from 'assets/logo.png'
import bg from 'assets/bg.png'
import ResetPassword from './components/ResetPassword';
import { toast } from 'react-toastify';

const LogIn = () => {
  const [ isResetPassword, setIsResetPassword ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const history = useHistory()
  const context =  useContext(SystemContext)
  const submitLogin = (values) => {
    setLoading(true)
    axios({
      method: 'POST',
      url: 'login', 
      data: {
        email: values.email,
        password: values.password
      }
    }).then((res) => {
      ls.set('user', res.data)
      ls.set('userToken', res.data.token)
      context.action.login(res.data)
      history.push('/dashboard')
    }).catch((err) => {
      console.log(err);
    }).then(() => setLoading(false))
  }

  const submitResetPassword = (values) => {
    setLoading(true)
    axios({
      method: 'POST',
      url: 'resetpassword', 
      data: {
        email: values.email,
      }
    }).then((res) => {
      setIsResetPassword(false)
    }).catch((err) => {
      console.log(err);
    }).then(() => setLoading(false))
  }

  const handleChangeForm = () => {
    setIsResetPassword(!isResetPassword)
  }

  return(
    <div className="account">
      <div className='d-flex justify-content-center align-items-center' style={{width: '60%'}}>
        <div className='d-flex flex-column justify-content-center align-items-center'>
          <img src={logo} width='50%' className='mb-4'/>
        </div>
      </div>
      <div className="account__wrapper">
        <div className="account__card">
          {
            isResetPassword ? 
            <ResetPassword 
              onSubmit={submitResetPassword} 
              changeForm={() => setIsResetPassword(!isResetPassword)}
              loading={loading}
            />
            :
            <LogInForm 
              onSubmit={submitLogin} 
              changeForm={() => setIsResetPassword(!isResetPassword)} 
              loading={loading}
            />
          }
        </div>
      </div>
    </div>
  )
};

export default LogIn;
