import React from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import { BsFolder, BsMegaphone, HiOutlineUserGroup, MdOutlineTopic, FaChalkboardTeacher, MdOutlinePayment, MdMailOutline, FiHelpCircle, FiPackage } from 'react-icons/all'
const SidebarContent = ({
  onClick,
  sidebarCollapse,
}) => (
  <div className="sidebar__content">
    <ul className="sidebar__block">
      <SidebarLink 
        title="Хянах самбар" 
        icon="home" 
        route="/dashboard" 
        onClick={onClick} 
      />
      <SidebarLink 
        title="Хэрэглэгч" 
        icon="user" 
        route="/users" 
        onClick={onClick} 
      />
      <SidebarLink 
        title="Сургалт" 
        icon="book" 
        route="/courses" 
        onClick={onClick} 
      />
      <SidebarLink 
        title="Сэдвүүд" 
        icon={<MdOutlineTopic color='#b1c3c8' size={15}/>} 
        route="/topics" 
        onClick={onClick} 
      />
      <SidebarLink 
        title="Контент сан" 
        icon={<BsFolder color='#b1c3c8' size={15}/>} 
        route="/localfolder" 
        onClick={onClick} 
      />
      <SidebarLink 
        title="Багш" 
        icon={<FaChalkboardTeacher color='#b1c3c8' size={15}/>} 
        route="/teachers" 
        onClick={onClick} 
      />
      <SidebarLink 
        title="Баннер" 
        icon={<BsMegaphone color='#b1c3c8' size={15}/>} 
        route="/banner" 
        onClick={onClick} 
      />
      <SidebarLink 
        title="Төлбөр тооцоо" 
        icon={<MdOutlinePayment color='#b1c3c8' size={15}/>} 
        route="/payment" 
        onClick={onClick} 
      />
      <SidebarLink 
        title=" И-Мэйл" 
        icon={<MdMailOutline color='#b1c3c8' size={15}/>} 
        route="/email" 
        onClick={onClick} 
      />
      <SidebarLink 
        title="Багц" 
        icon={<FiPackage color='#b1c3c8' size={15}/>} 
        route="/package" 
        onClick={onClick} 
      />
      <SidebarCategory 
        title="Харилцагч" 
        icon={<HiOutlineUserGroup color='#b1c3c8' size={15} />} 
        sidebarCollapse={sidebarCollapse}
      >
        <SidebarLink title="Байгууллага" route="/companies" onClick={onClick} />
        <SidebarLink title="Суралцагчид" route="/students" onClick={onClick} />       
      </SidebarCategory>
      <SidebarLink 
        title="Тусламж" 
        icon={<FiHelpCircle color='#b1c3c8' size={15}/>} 
        route={{pathname: "https://www.youtube.com/watch?v=Tv3rvyk9WzA&list=UUd_GCBZ3P1NUN4RurcvssTw"}}
        target='_blank'
        onClick={onClick} 
      />
    </ul>
  </div>
);

SidebarContent.propTypes = {
  onClick: PropTypes.func.isRequired,
  sidebarCollapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  sidebarCollapse: false,
};

export default SidebarContent;
