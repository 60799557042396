import React from 'react';
import Content from './views/Content';

const Dashboard = React.lazy(() => import('./views/Dashboard'));
const ExampleTwo = React.lazy(() => import('./views/ExampleTwo'));
const Users = React.lazy(() => import('./views/Users'));
const Courses = React.lazy(() => import('./views/Courses/index'));
const CourseProfile = React.lazy(() => import('./views/CourseProfile'));
const Topics = React.lazy(() => import('./views/Topics/index'));
const LocalFolder = React.lazy(() => import('./views/LocalFolder'));
const LocalFolderProfile = React.lazy(() => import('./views/LocalFolderProfile'));
const Teacher = React.lazy(() => import('./views/Teacher'));
const TeacherProfile = React.lazy(() => import('./views/TeacherProfile'));
const CustomerCompany = React.lazy(() => import('./views/Customers/company'));
const CompanyProfile = React.lazy(() => import('./views/Customers/company/profile'));
const Organization = React.lazy(() => import('./views/Organizations'));
const Student = React.lazy(() => import('./views/Customers/student'));
const StudentProfile = React.lazy(() => import('./views/Customers/student/profile'));
const CertificateGenerator = React.lazy(() => import('./components/CertificateGenerator'));
const Banner = React.lazy(() => import('./views/Banner'));
const PrivacyPolicy = React.lazy(() => import('./views/PrivacyPolicy'));
const Payment = React.lazy(() => import('views/Payment'));
const DeviceLog = React.lazy(() => import('views/DeviceLog'));
const Email = React.lazy(() => import('views/Email'));
const Packages = React.lazy(() => import('views/Packages'));
const PackageProfile = React.lazy(() => import('views/PackageProfile'));
const ExamPreview = React.lazy(() => import('views/ExamPreview'));

const routes = [
  { path: '/', exact: true, breadcrumb: 'Нүүр' },
  { path: '/dashboard', breadcrumb: 'Dashboard', component: Dashboard },
  { path: '/pages', breadcrumb: 'example', component: ExampleTwo },
  { path: '/users', breadcrumb: 'Хэрэглэгч', component: Users },
  { path: '/courses/:id', breadcrumb: 'Сургалтын дэлгэрэнгүй', component: CourseProfile, exact: true },
  { path: '/courses', breadcrumb: 'Сургалт', component: Courses, exact: true},
  { path: '/topics', breadcrumb: 'Сургалт', component: Topics},
  { path: '/localfolder/:id', breadcrumb: 'Local Folder Profile', component: LocalFolderProfile, exact: true},
  { path: '/localfolder', breadcrumb: 'Local Folder', component: LocalFolder, exact: true},
  { path: '/content', breadcrumb: 'Content', component: Content, exact: true},
  { path: '/teachers/:id', breadcrumb: 'Teacher Profile', component: TeacherProfile, exact: true},
  { path: '/teachers', breadcrumb: 'Teacher', component: Teacher, exact: true},
  { path: '/companies/:orgId', breadcrumb: 'Organization Profile', component: CompanyProfile, exact: true},
  { path: '/companies', breadcrumb: 'Customer Company', component: CustomerCompany, exact: true},
  { path: '/students/:id', breadcrumb: 'Student Profile', component: StudentProfile, exact: true},
  { path: '/students', breadcrumb: 'Student', component: Student, exact: true},
  { path: '/organization', breadcrumb: 'Organization', component: Organization,},
  { path: '/certificate/:id', breadcrumb: 'Certificate', component: CertificateGenerator, exact: true},
  { path: '/banner', breadcrumb: 'Banner', component: Banner,},
  { path: '/privacypolicy', breadcrumb: 'Privacy Policy', component: PrivacyPolicy,},
  { path: '/payment', breadcrumb: 'Payment', component: Payment,},
  { path: '/devicelog', breadcrumb: 'Payment', component: DeviceLog,},
  { path: '/email', breadcrumb: 'Email', component: Email,},
  { path: '/package/:packageId', breadcrumb: 'Package Profile', component: PackageProfile, exact: true},
  { path: '/package', breadcrumb: 'Packages', component: Packages, exact: true},
  { path: '/exampreview/:courseId', breadcrumb: 'Exam Preview', component: ExamPreview},
 
];

export default routes;
