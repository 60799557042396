import React from 'react';
import { NavLink } from 'react-router-dom';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import renderCheckBoxField from '@/shared/components/form/CheckBox';
import PasswordField from '@/shared/components/form/Password';
import { Formik, Form, Field } from 'formik';

const LogInForm = ({ onSubmit, changeForm, loading }) => (
  <Formik
    initialValues={{
      email: '',
      password: ''
    }}
    onSubmit={(values) => onSubmit(values)}
  >
    {({
      handleSubmit, 
      handleChange,
      values,
      setFieldValue
     }) => (
      <Form> 
        <div className="form">
          <h3 className='m-auto'>Удирдлагын систем</h3>
          <div className="form__form-group mt-2">
            <span className="form__form-group-label">И-Мэйл</span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <AccountOutlineIcon />
              </div>
              <input
                name="email"
                type="text"
                placeholder="И-Мэйл хаяг"
                values={values.username}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Нууц үг</span>
            <div className="form__form-group-field">
              <Field
                name="password"
                placeholder="Нууц үг"
                className="input-without-border-radius"
                keyIcon
                as={PasswordField}
                // values={values.password}
                onChange={handleChange}
              />
              <div className="account__forgot-password" style={{fontSize: 13, color: '#34aadc', cursor: 'pointer'}}>
                <div onClick={changeForm}>Нууц үгээ мартсан?</div>
              </div>
            </div>
          </div>
          <button 
            className="btn btn-primary account__btn account__btn--small" 
            type='submit' 
            disabled={loading}
          >
            НЭВТРЭХ
          </button>
        </div>
      </Form>
    )}
  </Formik>
);

export default LogInForm;
