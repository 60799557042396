import React from 'react';
import './styles.css'

const ChildComponent = ({grid, children}) => {


  return (
    <div className='foo-container container'>
      {children}  
    </div>
  )
}


function Content() {
  return (
    <ChildComponent grid={2}>
      <div style={{height: '50px', background: 'red', gridArea: 'foo1'}}>
        Hello 1
      </div>
      <div style={{height: '50px', background: 'red', gridArea: 'foo3'}}>
        Hello 2
      </div>
      <div style={{height: '50px', background: 'red', gridArea: 'foo2'}}>
        Hello 2
      </div>
      <div style={{height: '50px', background: 'red', gridArea: 'foo4'}}>
        Hello 2
      </div>
      <div style={{gridArea: 'foo'}}>
        <div style={{height: '50px', background: 'red', gridArea: 'foo3'}}>
          Hello 3
        </div>
        <div style={{height: '50px', background: 'red', gridArea: 'foo'}}>
          Hello 4
        </div>
      </div>
    </ChildComponent>
  )
}

export default Content;
