import React from 'react';

const MainWrapper = ({ children }) => (
  <div className="theme-light">
    <div>
      {children}
    </div>
  </div>
);

export default MainWrapper;
