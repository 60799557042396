import React, { useCallback, useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Modal, Button, Input, Slider } from 'antd';
import Cropper from 'react-easy-crop'
import { getCroppedImg } from '../../utils/cropImg';

const CropImageField = ({aspect, onChange}) => {
  const [ imageSrc, setImageSrc ] = useState(null)
  const [ crop, setCrop ] = useState({ x: 0, y: 0 })
  const [ zoom, setZoom ] = useState(1)
  const [ croppedAreaPixels, setCroppedAreaPixels ] = useState(null)
  const [ croppedImage, setCroppedImage ] = useState(null)

  const inputRef = useRef(null)

  useEffect(() => {
    showCroppedImage()
  },[croppedAreaPixels]) 
  const showCroppedImage = async () => {
    try {
      const fixedCroppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
      )
      setCroppedImage(fixedCroppedImage)
      onChange(fixedCroppedImage)
    } catch (e) {
    }
  }
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])
  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      reader.addEventListener('load', () => setImageSrc(reader.result))
      reader.readAsDataURL(e.target.files[0])
      setImageSrc(e.target.files[0])
      
    }
  }
  const handleChangeImgClick = () => {
    inputRef.current.click()
  }
  return (
      <div>
        {imageSrc &&
          <>
            <div className='position-relative' style={{width: 'auto', height: '350px'}}>
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                aspect={aspect}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}/>
            </div>
            <Slider value={zoom} step={0.1} onChange={(e) => setZoom(e) } />
          </>
        }
        <div className='d-flex justify-flex-start'>
          <Button
            className='mr-2'
            onClick={()=> handleChangeImgClick()}
          >
            {imageSrc ? 'Зураг солих' : 'Зураг оруулах'}
          </Button>
          {/* {
            imageSrc && 
            <Button
              type='primary'
              onClick={()=> onChange(croppedImage)}
            >
              Хадгалах
            </Button>
          } */}
        </div>
        <input style={{opacity: 0}} ref={inputRef} type="file" onChange={onFileChange}/>
      </div>
  );
};

export default CropImageField