import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import MainWrapper from './MainWrapper';

import PrivateRoute from '../utils/privateRoute';
import WrappedRoutes from './WrappedRoutes';
import ResetPassword from '../containers/ResetPassword';
import LogIn from '../containers/LogIn';
import Loading from './Loading';


const Router = () => (
  <MainWrapper>
    <main>
      <Suspense fallback={<Loading/>}>
        <Switch>
          <Route exact path="/log_in" component={LogIn} />
          <Route path="/reset_password" component={ResetPassword} />
          <PrivateRoute
            path="/"
            name="Home"
            render={(props) => <WrappedRoutes {...props} />}
          />
        </Switch>
      </Suspense>
    </main>
  </MainWrapper>
);

export default Router;
