import React from 'react'
import { NavLink } from 'react-router-dom';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import renderCheckBoxField from '@/shared/components/form/CheckBox';
import PasswordField from '@/shared/components/form/Password';
import { Formik, Form, Field } from 'formik';

function ResetPassword({onSubmit, changeForm, loading}) {
  return (
    <div>
      <Formik
        initialValues={{
          email: '',
        }}
        onSubmit={(values) => onSubmit(values)}
      >
        {({
          handleSubmit, 
          handleChange,
          values,
          setFieldValue
        }) => (
          <Form> 
            <div className="form">
              <h3 className='m-auto'>НУУЦ ҮГ СЭРГЭЭХ</h3>
              <div className="form__form-group mt-2">
                <span className="form__form-group-label">И-Мэйл</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <AccountOutlineIcon />
                  </div>
                  <input
                    name="email"
                    type="text"
                    placeholder="И-Мэйл хаяг"
                    values={values.username}
                    onChange={handleChange}
                  />
                </div>
                <div className='p-1 text-secondary mt-2' style={{backgroundColor: '#fed7aa'}}>
                  Та өөрийн бүртгэлтэй и-мэйл хаягаа оруулснаар таньд шинэ нууц үг илгээгдэх болно.
                </div>
              </div>
              <button 
                className="btn btn-primary account__btn account__btn--small" 
                type='submit' 
                disabled={loading}
              >
                Илгээх
              </button>
                <div className="m-auto" style={{fontSize: 13, color: '#34aadc', cursor: 'pointer'}}>
                  <div  onClick={changeForm}>Нэвтрэх хэсэг</div>
                </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default ResetPassword