import ConflictModal from 'components/ConflictModal';
import { SystemContext } from 'contexts';
import React, { Suspense, useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Layout from '../containers/Layout/index';
import routes from '../routes';

function WrappedRoutes() {

  const context = useContext(SystemContext)
  return (
    <div>
      <Layout />
      <div className="container__wrap">
        {
          routes.map((route, idx) => {
            return route.component && (
              <Route 
              key={idx}
              path={route.path}
              exact={route.exact}
              name={route.name}
              render={(props) => (
                <route.component {...props} />
                )}
                />
                )
          })
        }
        {/* <Redirect from="/" to="/dashboard" /> */}
      </div>
      <ConflictModal show={context.state.conflictModal}/>
    </div>
  )
}

export default WrappedRoutes;
