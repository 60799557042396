import React, { useReducer } from 'react';
import { toast } from 'react-toastify';
import ls from '../utils/ls';
const SystemContext = React.createContext()

const initialState = {
  loading: true,
  error: false,
  errorMessage: '',
  sidebarOpen: true,
  userToken: null,
  user: null,
  conflictModal: false,
  conflictErrorData: null,
  changeLoading: isLoading => {}
};

const reducer = (prevState, action) => {
  switch (action.type) {
    case 'RESTORE_TOKEN':
      return {
        ...prevState,
        userToken: action.token,
        user: action.info, 
        tasks: action.tasks,
        isLoading: false
      };
    case 'SIGN_IN':
      return {
        ...prevState,
        isSignout: false,
        userToken: action.token,
        user: action.info
      };
    case 'SIGN_OUT':
      return {
        ...prevState,
        isSignout: true,
        userToken: null,
      };
    case 'SIDEBAR_TOGGLE':
      return {
        ...prevState,
        sidebarOpen: action.status
      };
    case 'CONNECT_DB':
      return {
        ...prevState,
        db: action.db,
        dbLoading: false
      }
    case 'CONFLICT_MODAL_TOGGLE':
      return {
        ...prevState,
        conflictModal: action.status,
        conflictErrorData: action.data
      }
  }
  return prevState;
};

const SystemProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  React.useLayoutEffect(() => {
    const bootstrapAsync = async () => {
      let userToken;
      let userInfo;
      try {
        userToken = ls.get('userToken');
        userInfo = ls.get('user')
        dispatch({ type: 'RESTORE_TOKEN', token: userToken,  info: userInfo.profile});
      } catch (e) {
        console.log(e);
      }
    };

    bootstrapAsync();
  }, []);

  const actions = React.useMemo(
    () => ({
      onSuccess: (info) => {
        toast.success(info)
      },
      onError: (info) => {
        toast.error(info)
      },
      login: (info) => {
        dispatch({type: 'SIGN_IN', token: info.token, info: info.profile})
      },
      sidebarToggle: (status) => {
        dispatch({type: 'SIDEBAR_TOGGLE', status: status})
      }, 
      handleConflictModal: (status, data) => {
        dispatch({type: 'CONFLICT_MODAL_TOGGLE', status: status, data})
      },
    }),
    []
  );

  return (
    <SystemContext.Provider value={{state: state, action: actions}}>
      {props.children}
    </SystemContext.Provider>
  )
}

export {
  SystemContext,
  SystemProvider
}
