import React from 'react';
import { Link } from 'react-router-dom';

const TopbarMenuLinks = ({ title, icon, handleClick }) => (
  <button className="topbar__link" onClick={handleClick}>
    <span className={`topbar__link-icon lnr lnr-${icon}`} />
    <p className="topbar__link-title">{title}</p>
  </button>
);

export default TopbarMenuLinks;
