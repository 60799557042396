import React, { useContext, useEffect } from 'react';
import axios from "axios";
import ls from "../ls";
import { SystemContext } from '../../contexts';

const AxiosComponent = () => {
  const systemState = useContext(SystemContext)
  axios.defaults.baseURL = process.env.REACT_APP_API_URL

  useEffect(() => {
    axios.interceptors.request.use(
      (config) => {
        const user = ls.get("user");
        const token = user && user.token;
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      function (err) {
        return Promise.reject(err);
      }
    );
  
    axios.interceptors.response.use(
      function (response) {
        if(response.config.method === 'delete') {
          systemState.action.onSuccess('Амжилттай устгалаа!')
        }
        else if(response.config.method === 'post') {
          if(response.config.url.search('content/uploadcontent') !== -1  || response.config.url.search('coursesectionvideoupload') !== -1){
          
          }
          else{
            systemState.action.onSuccess('Үйлдэл амжилттай!')
          }
        }
        else if(response.config.method === 'put') {
          systemState.action.onSuccess('Амжилттай заслаа!')
        }
        return response;
      }, function (error) {
        if(error.code === 'ECONNABORTED') {
        }
        if(error.response.status == 409){
          systemState.action.handleConflictModal(true, error.response.data)
        }
        if(error.response) {
          if(error.response.data){
            if(error.response.data.value) {
              if(typeof(error.response.data.value) == 'object') {
                systemState.action.onError(error.response.data.value[0].msg)
              }
              else {
                systemState.action.onError(error.response.data.value)
              }
            }
            else {
              systemState.action.onError('Үйлдэл амжилтгүй боллоо!')
            }
          }
        }
        return Promise.reject(error);
      });
  }, [])

  

  return null
}

export default AxiosComponent
