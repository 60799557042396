import moment from 'moment';
import React, { useContext, useState } from 'react'
import ReactDOM from 'react-dom';
import { SystemContext } from 'contexts'
import styles from './style.module.css'
import { Button } from 'reactstrap';
import {AiOutlineClose} from 'react-icons/all'
import ls from 'utils/ls';
import { useHistory } from 'react-router-dom';

function ConflictModal({show}) {
  const [ data, setData ] = useState(null)
  const [ checked, setChecked ] = useState(false);
  const history = useHistory()
  const systemContext = useContext(SystemContext)

  if(!show){
    return null;
  }

  const handleCloseModal = () => {
    systemContext.action.handleConflictModal(false, null)
  }

  const handleLoginBtn = () => {
    ls.clear();
    history.push('/log_in')  
    systemContext.action.handleConflictModal(false, null)
  }

  return ReactDOM.createPortal(
    <div className={styles.wrapper}>
      <div className={styles.animate}>
        <div className={styles.content_wrapper}>
          <div className={styles.content_header}>
            <button className={styles.close_btn} type='button' onClick={() => handleCloseModal()}>
              <AiOutlineClose color='gray' size={18}/>
            </button>
          </div>
          <div className={styles.content_body}>
            <div className={styles.content_title}>
              Таны хаягаар давхар нэвтэрсэн байна. 
            </div>
            <div className={styles.table_wrapper}>
              <table>
                <thead style={{backgroundColor: 'rgb(254,215,170)', color: 'rgb(100, 116, 139)', fontWeight: 500 }}>
                  <tr>
                    <td className='pl-1'>IP</td>
                    <td className='w-[100px]'>Веб хөтөч</td>
                    <td>Хаанаас</td>
                    <td className='w-[100px]'>Төхөөрөмж</td>
                    <td>Хэзээ</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className='pr-2'>{systemContext.state.conflictErrorData.ip}</td>
                    <td className='pr-2'>{systemContext.state.conflictErrorData.browser}</td>
                    <td className='pr-2'>{systemContext.state.conflictErrorData.city}, {systemContext.state.conflictErrorData.country}</td>
                    <td className='pr-2'>{systemContext.state.conflictErrorData.device}</td>
                    <td className='pr-2'>{moment(systemContext.state.conflictErrorData.date).format('YYYY/MM/DD') }</td>
                  </tr>
                </tbody>
              </table>
              
            </div>
          </div>
          <Button 
          color='primary'
            className='bg-sky-400 text-white px-3 py-1 disabled:bg-gray-300 disabled:cursor-not-allowed mt-4 rounded' 
            onClick={handleLoginBtn}
          >
            Дахин нэвтрэх
          </Button>
        </div>
      </div>
    </div>,
    document.getElementById("conflict-modal")
  )
}

export default ConflictModal
